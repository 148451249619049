import React from 'react'
import './DonorDonationCard.scss'
import Slider from "react-slick";
import dynamic from 'next/dynamic'
import Donation from '../../../Components/SVG/Donation';
const Arrow = dynamic(
    () => import('../../../Components/Arrow/Arrow'),
    { loading: () => <p>...</p> }
)
import FormatNumber, { floatFormatter } from '../../../Utility/FormatNumber';
import CTAAnchorTag from '../../../Components/CTAAnchorTag/CTAAnchorTag';


const DonorDonationCard = ({ donorData , handleRedirect = () => {}, isAllCleared = false }) => {

    var settings = {    
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: donorData.length >= 2 ? 2 : donorData.length,
        slidesToScroll: donorData.length >= 2 ? 2 : donorData.length,
        // slidesToShow: 2,
        nextArrow: <Arrow type="right"  tabIndex={0}/>,
        prevArrow: <Arrow type="left" tabIndex={0}/>,
        responsive: [
            // {
            //   breakpoint: 1024,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 3,
            //     infinite: true,
            //     dots: true
            //   }
            // },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: donorData.length >= 2 ? 2 : donorData.length,
                    slidesToScroll: donorData.length >= 2 ? 2 : donorData.length,
                    initialSlide: donorData.length >= 2 ? 2 : donorData.length
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    

    return (
        <div className="donor-donation__card">
            <Slider {...settings}>
                {
                   donorData && !isAllCleared && donorData.map((items, index) => {
                    return <CTAAnchorTag key={items?.sfid || items?.heroku_id} tabIndex={-1} aria-describedby={items.name} aria-label={items.name} handleClick={(e) => handleRedirect(e, items)}>
                            <div className="donor-progress-tile-container"  tabIndex={0}>
                                <div className='donor-progress-tile-container__card'>
                                    <div className='donor-progress-tile-container__header'>
                                        <div className='donor-progress-tile-container__header-content'>
                                          {items.name}
                                        </div>
                                        <div className='donor-progress-tile-container__header-image' >
                                        <Donation width={40} height={40}/>
                                        </div>
                                    </div>
                                    <div className='donor-progress-tile-container__body'>
                                      <div className='donor-progress-tile-container__body-content'>
                                        <div className='donor-progress-tile-container__body-item-amount'>
                                          ${FormatNumber(floatFormatter(items.total_donation_received__c))}
                                        </div>
                                        <div className='donor-progress-tile-container__body-goal-amount'>
                                        Goal: ${FormatNumber(floatFormatter(items.scholarship_fund_amount__c))}
                                        </div>
                                      </div>
                                      <div className='donor-progress-tile-container__bar'>
                                        <div className='donor-progress-tile-container__bar-progress'>
                                          <div className='donor-progress-tile-container__bar-progress-inner'>
                                            <div className='donor-progress-tile-container__bar-progress-percent' style={{width:`${items.donation_progress__c ? items.donation_progress__c+'%' : '0%'}`}}>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='donor-progress-tile-container__bar-content'>
                                          {`${items.donation_progress__c ? items.donation_progress__c+'%' : '0%'}`}
                                        </div>
                                      </div>
                                    </div>
                                      
                                </div>
                            </div>
                            </CTAAnchorTag>
                    })
                }
            </Slider>
        </div>
    )

}


export default DonorDonationCard;