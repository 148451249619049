/**
 * Returns a greeting based on the current local time.
 * @returns {string}
 */
export default () => {
    const currentDate = new Date();
    const hrs = currentDate.getHours();

    switch (true) {
        case hrs < 12:
            return 'Good Morning';
        case hrs >= 12 && hrs <= 17:
            return 'Good Afternoon';
        default:
            return 'Good Evening';
    }
}
