import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillWhite, width = "50", height = "50", secondaryColor = MAIN_CONFIG.COLORS.fillRed, ariaLabel = "Donation", ariaLabelDescription = "Donation" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50">
            <title>{ariaLabel}</title>
            <path d="M26.14,9.76A3.83,3.83,0,1,0,30,13.58,3.82,3.82,0,0,0,26.14,9.76Zm-4.87,3.82a4.88,4.88,0,1,1,4.87,4.88A4.87,4.87,0,0,1,21.27,13.58Z" fill={color}/>
            <path d="M18.48,19.54a3.83,3.83,0,1,0,3.82,3.83A3.83,3.83,0,0,0,18.48,19.54ZM13.6,23.37a4.88,4.88,0,1,1,4.88,4.87A4.88,4.88,0,0,1,13.6,23.37Z" fill={color}/>
            <path d="M35.87,27.19a.52.52,0,0,0-.52.53v.57a13.27,13.27,0,0,0-3.74.52A8.62,8.62,0,0,0,29.25,30l-.14.09a7.92,7.92,0,0,1-1.77,1,9.92,9.92,0,0,1-3.37.47H21.79a2.69,2.69,0,0,0-2.61,2l-2.36-.78h0c-1.56-.53-3.11-1.05-4.4-1.41a10.82,10.82,0,0,0-2.73-.48,1.94,1.94,0,0,0-1,.24,1.08,1.08,0,0,0-.52.95A3,3,0,0,0,9,33.9a11.13,11.13,0,0,0,1.85,1.64A26.87,26.87,0,0,0,15,38h0l2.07,1c1.2.57,2.21,1.06,3.1,1.43a9.48,9.48,0,0,0,3.75.89,26.29,26.29,0,0,0,7.19-1.6c.73-.24,1.43-.49,2.07-.71h0l1.09-.38c.43-.14.8-.26,1.09-.34v1.42a.52.52,0,0,0,.52.53h5.44a.53.53,0,0,0,.53-.53v-12a.53.53,0,0,0-.53-.53Zm-15.73,7a1.65,1.65,0,0,1,.49-1.15,1.64,1.64,0,0,1,1.16-.49H24a10.74,10.74,0,0,0,3.73-.54,8.58,8.58,0,0,0,2-1.08h0l.14-.1a8,8,0,0,1,2.09-1.05,12.25,12.25,0,0,1,3.43-.48v7.83l-.29.07c-.31.09-.7.22-1.14.37L32.8,38h0l-2,.69a25.45,25.45,0,0,1-6.86,1.55,8.61,8.61,0,0,1-3.35-.81c-.86-.36-1.83-.83-3-1.39-.64-.31-1.33-.65-2.11-1a26.66,26.66,0,0,1-3.95-2.34,11.08,11.08,0,0,1-1.68-1.47,2.08,2.08,0,0,1-.6-1.15s0,0,0-.06h0a1,1,0,0,1,.47-.08,10.3,10.3,0,0,1,2.44.45c1.27.35,2.76.85,4.29,1.37h0l2.69.89a2.67,2.67,0,0,0,.76,1.51,2.7,2.7,0,0,0,1.91.79h5.44a.52.52,0,0,0,.52-.52.52.52,0,0,0-.52-.53H21.79a1.63,1.63,0,0,1-1.16-.48,1.68,1.68,0,0,1-.49-1.16" fill={color} />
        </svg>

    );
};
