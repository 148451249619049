import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Progress } from 'antd';
import AppPage from '../../../Core/AppPage';
import './donor-home-page.scss';
import { orderBy } from 'lodash';
import { MAIN_CONFIG } from '../../../../config/main';
import CurrentDayGreeting from '../../../Utility/CurrentDayGreeting';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { toast } from 'react-toastify';
import FilterSelector from '../../../Components/FilterSelector/FilterSelector';
import Slider from "react-slick";
import DonorDonationCard from '../DonorDonationCard/DonorDonationCard';
import { clearSessionFilters, alretMsg } from '../../../Utility/ApplicationFormUtility';
import { handleRedirectHrefUrl } from '../../../Utility/HelperFunctions';

const CustomError = dynamic(
    () => import('../../Custom404/CustomError'),
    { loading: () => <p>...</p> }
)



const Arrow = dynamic(
    () => import('../../../Components/Arrow/Arrow'),
    { loading: () => <p>...</p> }
)


const FieldCheckbox = dynamic(
    () => import('../../../Components/SVG/FieldCheckbox'),
    { loading: () => <p>...</p> }
)


const CTAButton = dynamic(
    () => import('../../../Components/CTAButton/CTAButton'),
    { loading: () => <p>...</p> }
)

const Globe = dynamic(
    () => import('../../../Components/SVG/Globe'),
    { loading: () => <p>...</p> }
)

const Basketball = dynamic(
    () => import('../../../Components/SVG/Basketball'),
    { loading: () => <p>...</p> }
)

const Medal = dynamic(
    () => import('../../../Components/SVG/Medal'),
    { loading: () => <p>...</p> }
)

const TaskList = dynamic(
    () => import('../../../Components/TaskList/TaskList'),
    { loading: () => <p>...</p> }
)

const DonorFeedBack = dynamic(
    () => import('../../../Components/DonorFeedBack/DonorFeedBack'),
    { loading: () => <p>...</p> }
)

const FlashMessage = dynamic(
    () => import('../../../Components/FlashMessage/Message'),
    { loading: () => <p>...</p> }
)

const PercentChange = dynamic(
    () => import('../../../Components/DonorDashboard/PercentChange/PercentChange'),
    { loading: () => <p>...</p> }
)

const TotalCount = dynamic(
    () => import('../../../Components/DonorDashboard/TotalCount/TotalCount'),
    { loading: () => <p>...</p> }
)

const Table = dynamic(
    () => import('../../../Components/Table/Table'),
    { loading: () => <p>...</p> }
)


const Loader = dynamic(
    () => import('../../../Components/Loader/Loader'),
    { loading: () => <p>...</p> }
)

const CardListingLoader = dynamic(
    () => import('../../../Components/Loader/Loader'),
    { loading: () => <p>...</p> }
)


export const DonorHome = ({ pageData, setActiveHeaderNav, accountData, setModalState, pageType }) => {
    const router = useRouter()
    const API = new KaleidoscopeAPI({});
    setActiveHeaderNav('overview');
    const [submitted, setSubmitted] = useState(false);
    const [tileLoading, setTileLoading] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false)
    document.cookie = `currentAccount=${accountData.currentAccount.sfid};path=/`;

    const columnData = {
        table_headers: [
            { field_api_name__c: 'Name', column_name__c: 'Scholarship' },
            { field_api_name__c: 'total_reviewers', column_name__c: 'Assigned Reviewers' },
            { field_api_name__c: 'reviewer_completed', column_name__c: '% Complete' },
        ]
    };

    const { user: { first_name }, currentAccount } = accountData;
    const {donor_instructions__c, elements_program_s__c, enable_form_builder__c, sfid} = currentAccount;

    const { notifications, tasks, scholarship_reviewer_progress, persona_branding, accountId, account, accounts, tile_dropdown, tile_scholarships, donation_tile_dropdown, donation_tile_scholarships } = pageData;

    if (!persona_branding) {
        return <CustomError setModalState={setModalState} hideHeader={true} message="Your account is not ready yet, please check back later!" />
    }
    /**
     * Feedback modal content and shows the modal.
     */
    const activeTileSet = () => {
        if (tile_scholarships?.length > 0) {
            let activeTile = []
            tile_scholarships?.map(row => {
                activeTile.push(row.heroku_id)
            })
            return activeTile
        } else {
            return null
        }
    }


    const activeTileSetDonation = () => {
        if (donation_tile_scholarships?.length > 0) {
            let activeTile = []
            donation_tile_scholarships?.map(row => {
                activeTile.push(row.heroku_id)
            })
            return activeTile
        } else {
            return null
        }
    }

    // API.fetchAccountStats({ token: authToken, accountId })
    const [activeFilters, setActiveFilters] = useState({})
    const [selectedFilters, setSelectedFilters] = useState(activeTileSet())
    const [tileScholarships, setTileScholarships] = useState(tile_scholarships || [])

    const [donationDetails, setDonationDetails] = useState(donation_tile_scholarships || [])
    const [selectedFiltersDonation, setSelectedFiltersDonation] = useState(activeTileSetDonation())
    const [activeFiltersDonation, setActiveFiltersDonation] = useState({})
    // const {
    //     all_time_awarded_amount,
    //     amount_disbursed_this_year,
    //     application_week_ratio,
    //     new_application_this_week,
    //     remaining_to_disburs_this_year,
    //     reviewer_count,
    //     total_application,
    //     total_application_this_year,
    //     total_awarded_this_year
    // } = application_stat_analyses;
    const showUnsubscribeConfirmationPopup = localStorage.getItem('show_unsubscribe_confirmation') || false

    useEffect(() => {
        // (async () => {
        //     if (accounts.length > 0 && accounts.find(a => a.sfid === accountId).enable_nps_score) {
        //         await showModal()
        //     }
        // })();
        if (showUnsubscribeConfirmationPopup === 'true') {
            alretMsg("", setIsOpenSweetAlert, () => { }, false, '', () => {
                localStorage.setItem('show_unsubscribe_confirmation', false);
            },
                'Email Unsubscribe Confirmation',
                true,
                false
            );
            const timeoutId = setTimeout(() => {
                setIsOpenSweetAlert(false);
                localStorage.setItem('show_unsubscribe_confirmation', false);
            }, 30000);
            return () => clearTimeout(timeoutId);
        }

    }, []);

    useEffect(() => {
        clearSessionFilters(pageType !== sessionStorage.getItem("appliedFiltersTab"))
       }, [pageType])
    /**
     * Feedback modal content and shows the modal.
     */
    const showModal = async () => {
        setModalState({
            visible: true,
            id: 'applicant-task__create',
            content: <DonorFeedBack handleClose={closeModal} feedbackSubmit={feedbackSubmit} />
        });
    }

    /**
     * Closes the modal.
     */
    const closeModal = async () => {
        setModalState({ visible: false });
        let npsScore = {
            nps_score: {
                cancelled__c: true
            }
        }
        await API.donorFeedBack({ accountId: accountId, token: accountData.token, npsScore })
    }

    /**
     * feedback submit.
     */
    const feedbackSubmit = async (ratingScore, ratingDesc) => {

        let npsScore = {
            nps_score: {
                satisfaction_score__c: ratingScore,
                reason_for_score__c: ratingDesc
            }
        }

        let response = await API.donorFeedBack({ accountId: accountId, token: accountData.token, npsScore })
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            window.location.reload();
        } else {
            toast.error(<FlashMessage message={response.message || `Something went wrong.`} />);
        }

    }

    const addFilters = filters => {
        setSubmitted(true)
        let newFilters = {
            ...activeFilters,
        }
        for (let i = 0, total = filters.length; i < total; i++) {
            const filter = filters[i]
            if (!filter.value) {
                delete newFilters[filter.type]
            } else {
                newFilters[filter.type] = filter.value
            }
        }
        let concatArray = newFilters.tiles ? filterActiveTiles(donationDetails).concat(newFilters.tiles) : filterActiveTiles(donationDetails)
        apiCallForAddRemoveTiles(concatArray|| [])
        setActiveFilters(newFilters)
    }

    const removeFilters = filterTypes => {
        setSubmitted(true)
        const currentFilters = activeFilters
        for (let i = 0, total = filterTypes.length; i < total; i++) {
            delete currentFilters[filterTypes[i]]
        }
        apiCallForAddRemoveTiles(filterActiveTiles(donationDetails) || [])
        setActiveFilters(Object.assign({}, currentFilters))
    }

    const apiCallForAddRemoveTiles = async (tiles) => {
        let response = await API.addRemoveTiles({ accountId: accountId, token: accountData.token, data: { scholarship_ids: tiles } })
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            let result = await API.fetchAccountStats({ token: accountData.token, accountId })
            setTileScholarships(result.tile_scholarships)
            setDonationDetails(result.donation_tile_scholarships)
        } else {
            toast.error(<FlashMessage message={response.message || `Something went wrong.`} />);
        }
        setSubmitted(false)
    }


    /***Scholarship selector - filters */

    const filters = [{
        type: "tiles",
        label: "Programs",
        options: orderBy(tile_dropdown, [tile => tile.label.toLowerCase()], ['asc']) || [],
        isMultiple: true
    }]


    /**React slick */
    const handleRedirect = (e, scholarship) => {
        e.preventDefault();
        setSubmitted(true)
        if (scholarship?.elements_program__c) {
            handleRedirectHrefUrl(MAIN_CONFIG.ELEMENTS_URLS.DONOR.ALL_APPLICATIONS(scholarship?.sfid))
          } else {
            router.push(MAIN_CONFIG.URLS.DONOR.SINGLE_SCHOLARSHIP(scholarship?.sfid || scholarship?.heroku_id), undefined, { shallow: true })
        }
    }

    const handleCreateProgram = async (e) => {
        e.preventDefault()
        await API.notifyAccountManager({token: accountData.token, accountId: sfid})
        handleRedirectHrefUrl(MAIN_CONFIG.ELEMENTS_URLS.DONOR.CREATE_NEW_SCHOLARSHIP)
    }

    const titles = (scholarships) => {
        scholarships = orderBy(scholarships, [tile => tile.name.toLowerCase()], ['asc']);
        return scholarships.map((scholarship) => {
            const { name, inprogress_applications_count, submitted_applications_count, status__c } = scholarship

            const submittedProgress = ((submitted_applications_count / (submitted_applications_count + inprogress_applications_count)) * 100)
            const cardClassName = `my-sk-slide ${(donationDetails.length <= 0) ? 'my-sk-slide-margin' : ''}`;
            return (
                <button key={scholarship?.sfid || scholarship?.heroku_id} tabIndex={-1} aria-label='Click to navigate' onClick={(e) => handleRedirect(e, scholarship)}>
                    <button className={cardClassName} tabIndex={0} aria-label={name}>
                        <div className='scholarship-progress-tiles-container__card'>
                            <div className='scholarship-progress-tiles-container__card__header'>
                                {name}
                            </div>
                            <div className='scholarship-progress-tiles-container__card__content'>
                                <div className='scholarship-progress-tiles-container__card__content__status'>
                                    {status__c}
                                </div>
                                <div className='scholarship-progress-tiles-container__card__content__graph'>
                                    <div className="circular c100 p85 orange">
                                        <span className="scholarship-progress-tiles-container__percent__count">
                                            <div className="scholarship-progress__custom-bar-container">
                                                <div className="inner">
                                                    <div className='scholarship-progress-tiles-container__card__content__graph__progress-count'>
                                                        {inprogress_applications_count}
                                                    </div>
                                                    <div className='scholarship-progress-tiles-container__card__content__graph__progress-title'>
                                                        Apps In Progress
                                                    </div>

                                                    <div className='scholarship-progress-tiles-container__card__content__graph__progress-divider'>
                                                    </div>

                                                    <div className='scholarship-progress-tiles-container__card__content__graph__submitted-count'>
                                                        {submitted_applications_count}
                                                    </div>
                                                    <div className='scholarship-progress-tiles-container__card__content__graph__submitted-title'>
                                                        Apps Submitted
                                                    </div>
                                                </div>
                                            </div>

                                        </span>
                                        <div className="slice scholarship-progress-tiles-container__slice">
                                            <Progress type="circle" percent={submittedProgress} />
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='scholarship-progress-tiles-container__card__footer'>
                                <ul className='scholarship-progress-tiles-container__card__footer__status-indicator-list'>
                                    <li className='scholarship-progress-tiles-container__card__footer__status-indicator-list__item'><div className='indicator-status-draft-marker'></div>Draft</li>
                                    <li className='scholarship-progress-tiles-container__card__footer__status-indicator-list__item'><div className='indicator-status-submitted-marker'></div>Submitted</li>
                                </ul>
                            </div>
                        </div>
                    </button>
                </button>

            )
        })
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: tileScholarships.length >= 3 ? 3 : tileScholarships.length,
        slidesToScroll: tileScholarships.length >= 3 ? 3 : tileScholarships.length,
        nextArrow: <Arrow type="right" />,
        prevArrow: <Arrow type="left" />,
        responsive: [
            // {
            //   breakpoint: 1024,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 3,
            //     infinite: true,
            //     dots: true
            //   }
            // },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: tileScholarships.length >= 2 ? 2 : tileScholarships.length,
                    slidesToScroll: tileScholarships.length >= 2 ? 2 : tileScholarships.length,
                    initialSlide: tileScholarships.length >= 2 ? 2 : tileScholarships.length
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const filterActiveTiles = (activeTiles) =>{
        return activeTiles.map((el)=>el.heroku_id)
    }


    // donation card filter 
    const donationfilters = [{
        type: "tiles",
        label: "Donations",
        options: orderBy(donation_tile_dropdown, [tile => tile.label.toLowerCase()], ['asc']) || [],
        isMultiple: true
    },]
const [isAllCleared , setIsAllCleared] = useState(false)
    const removeDunationFilter = filterTypes => {

        setSubmitted(true)
        const currentFilters = activeFiltersDonation

        for (let i = 0, total = filterTypes.length; i < total; i++) {
            delete currentFilters[filterTypes[i]]
        }

        apiCallForAddRemoveTiles(filterActiveTiles(tileScholarships) || [])
        setActiveFiltersDonation(Object.assign({}, currentFilters))
        // window.location.reload()
        setIsAllCleared(true)
    }

    const addFiltersDonation = filters => {
        setSubmitted(true)
        let newFilters = {
            ...activeFiltersDonation,
        }

        for (let i = 0, total = filters.length; i < total; i++) {
            const filter = filters[i]
            if (!filter.value) {
                delete newFilters[filter.type]
            } else {
                newFilters[filter.type] = filter.value
            }
        }

        let concatArray = newFilters.tiles ? filterActiveTiles(tileScholarships).concat(newFilters.tiles) : filterActiveTiles(tileScholarships)
        apiCallForAddRemoveTiles(concatArray || [])
        setActiveFiltersDonation(newFilters)
        setIsAllCleared(false)
    }


    return (
        <div id='donor-home-page'>
            <div id='donor-home-page__inner'>
                <div id='donor-home-page__top'>
                    <div id='donor-home-page__top-copy'>
                        <h1 id='donor-home-page__greeting' aria-label={`${CurrentDayGreeting().toUpperCase()} ${first_name.toUpperCase()}!`} className='H1DesktopGreen'>{CurrentDayGreeting()}, {first_name}!</h1>
                        <span
                            className='BodyDefaultRegularBlack'
                            id='donor-home-page__top-tagline'
                            dangerouslySetInnerHTML={{ __html: donor_instructions__c }}
                        />
                        {tile_dropdown?.length > 0 && <div id='donor-home-page__top-programs'>Programs:</div>}
                    </div>
                </div>

                <Loader loading={submitted} />

                <div id='program_filter_create'>
                <div className='H5DesktopGreen program_filter'>
                {tile_dropdown?.length > 0 &&
                    <FilterSelector
                        // className="applicant-home__filter"
                        label={{
                            singular: '',
                            plural: '',
                        }}
                        filters={filters}
                        addFilters={addFilters}
                        removeFilters={removeFilters}
                        activeFilters={activeFilters}
                        selectedFilters={selectedFilters}
                        notShowHead={false}
                        accountData={accountData}
                        pageType={pageType}
                    />
                }
                </div>
                    {
                        elements_program_s__c && enable_form_builder__c &&
                        <CTAButton className="create-scholarship-button" tabIndex={0} onClick={(e) => handleCreateProgram(e)} aria-label="Create Program Button">Create Program</CTAButton>
                    }
                </div>
                <div className="scholarship-progress-tiles-container">
                    <div className="">
                        <Slider {...settings}>
                            {titles(tileScholarships)}
                        </Slider>
                    </div>
                </div>
                {donation_tile_dropdown?.length>0 &&
                (<>
                <div className='donor-home-page__donation-filter'>
                    <div id='donor-home-page__top-donations'>Donations:</div>
                    <div className='H5DesktopGreen program_filter'>
                        <FilterSelector
                            // className="applicant-home__filter"
                            label={{
                                singular: '',
                                plural: '',
                            }}
                            filters={donationfilters}
                            addFilters={addFiltersDonation}
                            removeFilters={removeDunationFilter}
                            activeFilters={activeFiltersDonation}
                            selectedFilters={selectedFiltersDonation}
                            notShowHead={false}
                            accountData={accountData}
                            pageType={pageType}
                        />
                    </div>
                </div>
                <div className='donor-donation-card-buttom'>
                <DonorDonationCard
                        donorData={donationDetails}
                        handleRedirect={handleRedirect}
                        isAllCleared={isAllCleared}
                    />
                </div>
                </>)
                }

            </div>
            {isOpenSweetAlert}
        </div>
    );

}

DonorHome.propTypes = {
    pageType: PropTypes.string,
    accountData: PropTypes.object,
    setActiveHeaderNav: PropTypes.func,
    setModalState: PropTypes.func,
    pageData:PropTypes.object
  };
  
const DonorHomePage = AppPage(DonorHome);

export default DonorHomePage;
